import React from "react"
import PropTypes from "prop-types"

import {
  Hero,
  HeroContainer,
  HeroImageCol,
  HeroContentCol,
  HeroImageContainer,
  HeroImage,
  HeroTitle,
  PinkCircle,
  VisitKineduButton,
  HeroDescription,
  VisitKineduContainer,
} from "./styles"

const ForParentsHero = ({ image, innerRef }) => {
  const handleGoToKinedu = () => {
    let url = "https://kinedu.com"

    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      url =
        "https://apps.apple.com/us/app/kinedu-baby-development-plan/id741277284"
    } else if (/android/i.test(navigator.userAgent)) {
      url =
        "https://play.google.com/store/apps/details?id=com.kinedu.appkinedu&hl=en&gl=US"
    }
    window.open(url, "_blank")
  }

  return (
    <Hero ref={innerRef}>
      <PinkCircle />
      <HeroContainer>
        <HeroImageCol>
          <HeroImageContainer>
            <HeroImage src={image} />
          </HeroImageContainer>
        </HeroImageCol>
        <HeroContentCol padded>
          <div style={{ paddingLeft: "20px" }}>
            <HeroTitle>
              Complete integration <br /> with our award - <br /> winning app
            </HeroTitle>
            <HeroDescription>
              Support your students development at home with the help of their
              parents.
            </HeroDescription>
          </div>

          <VisitKineduContainer>
            <VisitKineduButton
              onClick={() => {
                handleGoToKinedu()
              }}
            >
              {" "}
              Visit kinedu{" "}
            </VisitKineduButton>
          </VisitKineduContainer>
        </HeroContentCol>
      </HeroContainer>
    </Hero>
  )
}

ForParentsHero.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default ForParentsHero
