import styled from 'styled-components';
import { Input, FormField } from "../LoginCTAForm/styles"
import { Button } from "kinedu-react-components";

export const Wrapper = styled.div`
margin-top: 24px;
display: flex;
flex-direction: column;

${({ theme }) => `
${theme.breakPoints.largeTablet} {
  flex-direction: row;
}
`}
`;

export const SubmitButton = styled(Button).attrs(() => ({
  rounded: true,
  flat: true,
}))`
  width: 100%;
  height: 50px;
  background-color: #73B84E;
  color: white;
  margin-bottom: 30px;

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    width: 188px;
    height: 50px;
    margin-top: 30px;
  }
`}
`;

export const JoiningForm  = styled.form.attrs(() => ({
  className: 'JoiningForm',
}))`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    flex-direction: row;
  }
`}
`;

export const FormFieldContainer = styled(FormField)`

  width: 100%;
  margin: 0;

${({ theme }) => `
${theme.breakPoints.xLargePhone} {
  width: 34%;
  margin-right: 30px;
}
`}
`;
