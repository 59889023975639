import React from 'react';
import DevelopmentGuidence from 'images/landing/forparents_1.png';
import TrackDevelopment from 'images/landing/forparents_2.png';
import DirectSupport from 'images/landing/forparents_3.png';
import {
  ImageContent,
  Title,
  SecondTitle,
  Description,
  Row,
  ContentDescription,
  Content,
} from './styles';

const ForParentsContent = () => {
  return (
    <Content>
      <div>
      <Row description={'row-reverse'} >
        <div style={{ textAlign: 'center' }}>
          <ImageContent src={DevelopmentGuidence} />
        </div>
        <ContentDescription justify={'right'}>
        <Title>Unlock personalized</Title>
        <SecondTitle>development guidance</SecondTitle>
        <Description>
          Get full access to Kinedu’s library of 2,200+ activities and <br />
          articles and personalized activity recommendations <br />
          designed to help your baby reach new milestones.
        </Description>
        </ContentDescription>
      </Row>

      <Row description={'row'}>
        <div style={{ textAlign: 'center' }}>
          <ImageContent src={TrackDevelopment} />
        </div>
        <ContentDescription justify={'left'}>
        <Title>Keep track of</Title>
        <SecondTitle>your child’s development</SecondTitle>
        <Description>
          Monitor your child’s progress in four key developmental <br />
          areas and identify upcoming milestones.
        </Description>
        </ContentDescription>

      </Row>

      <Row description={'row-reverse'} >
        <div style={{ textAlign: 'center' }}>
          <ImageContent src={DirectSupport} />
        </div>
        <ContentDescription justify={'right'}>
        <Title>Direct support</Title>
        <SecondTitle>for at home learning</SecondTitle>
        <Description>
          Go the extra mile and build skills together at home with <br />
          activity suggestions and step-by-step instructions from your <br />
          child’s teacher.
        </Description>
        </ContentDescription>
      </Row>
      </div>
    </Content>
  )
}

export default ForParentsContent;
