import React from "react"
import styled, { ThemeProvider } from "styled-components"
import Layout from "components/LandingLayout"
import metaTranslations from "../lib/utils"
import SEO from "components/seo"
import theme from "../themes"
import NavbarComponent from "../components/Landing/Navbar/Navbar"
import { windowGlobal } from "../config"
import ForParentsHero from 'components/Landing/ForParentsHero/ForParentsHero'
import Hero from '../images/landing/for_parents.png';
import ForParentsContent from 'components/Landing/ForParentsContent/ForParentsContent';
import ForParentForm from 'components/Landing/ForParentsForm/ForParentsForm';
import { WithTrans } from "../language/withTrans"

const FormContainer = styled.div`
padding-left: 20px;
padding-right: 20px;
padding-top: 60px;

${({ theme }) => `
${theme.breakPoints.desktop} {
  margin-bottom: 80px;
  padding-left: 189px;
}
`}
`;

const FormTitle = styled.p`
font-size: 20px;
color: #0084DB;
font-weight: bold;
margin: 0;
line-height: 26px;

${({ theme }) => `
${theme.breakPoints.largeTablet} {
  font-size: 36px;
}
`}
`;

const ForParents = ({ lang }) => {
  windowGlobal.localStorage.setItem("LANDING_LOCALE", lang);

  return (
    <Layout>
      <SEO
        title={metaTranslations[lang].title}
        description={metaTranslations[lang].description}
        meta={[
          {
            name: "og:image",
            content:
              "https://kinedu-media.s3.amazonaws.com/classrooms_by_kinedu_2.jpg",
          },
        ]}
      />
      <ThemeProvider theme={theme}>
        <NavbarComponent lang={"es"} />
        <ForParentsHero
            image={Hero}
          />
        <ForParentsContent />
        <FormContainer>
          <FormTitle>Interested in joining? Invite your kids school to join today!</FormTitle>
          <ForParentForm />
        </FormContainer>
      </ThemeProvider>
    </Layout>
  )
}

export default WithTrans()(ForParents);
