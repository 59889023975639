import styled from 'styled-components';

export const ImageContent = styled.img`
  width: 320px;
  ${({ theme }) => `
  ${theme.breakPoints.largeTablet} {
    width: 464px;
  }
`}
`;

export const Title = styled.p`
  color: #0093DD;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  ${({ theme }) => `
  ${theme.breakPoints.largeTablet} {
    font-size: 36px;
    margin-bottom: 22px;
  }
`}
`;

export const SecondTitle = styled.p`
  color: #424242;
  font-size: 20px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 0px;
  ${({ theme }) => `
  ${theme.breakPoints.largeTablet} {
    font-size: 36px;
  }
`}
`;

export const Description = styled.p`
  font-size: 16px;
  color: #8B8B8B;
  font-weight: 400;
  line-height: 24px;

  br {
    display: none;
  }
  ${({ theme }) => `
  ${theme.breakPoints.largeTablet} {
    line-height: 32px;

    br {
      display: block;
    }
  }
`}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme, description }) => `
  ${theme.breakPoints.largeTablet} {
    flex-direction: ${description};
  }
`}
`;

export const ContentDescription = styled.div`
text-align: center;
display: flex;
flex-direction: column;
${({ theme, justify }) => `
${theme.breakPoints.largeTablet} {
  text-align: ${justify};
  justify-content: center;
  padding-${justify}: 30px;
}
`}
`;

export const Content = styled.div`
height: auto;
background-color: #E5F3FC;
margin: 0 auto;
padding-bottom: 54px;
display: flex;
justify-content: center;
`;
