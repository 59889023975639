import styled from 'styled-components';
import { Button } from "kinedu-react-components"

export const Hero = styled.section.attrs(() => ({
  className: 'LandingHero',
}))`
  padding: 0;
  padding-top: 6rem;
  position: relative;
  overflow: hidden;
  ${({ theme }) => `
    ${theme.breakPoints.largeTablet} {
      padding: 8rem 0 8rem 0;
    }
  `}
`;

export const HeroContainer = styled.div`
  display: flex;
  flex-direction:column;
  max-width: 1060px;
  margin: 0 auto;
  .HeroImageCol {
    width: 100%;
  }
  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      padding: 4rem 0;
    }
    ${theme.breakPoints.largeTablet} {
      padding: 5rem 0;
      flex-direction: row;
      max-width: 1060px;
      margin: 0 auto;
      .HeroImageCol:first-child {
        width: 45%;
      }
      .HeroImageCol:last-child {
        width: 55%;
      }
    }
  `}
`;

export const HeroImageCol = styled.div.attrs(() => ({
  className: 'HeroImageCol',
}))`
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  position: relative;
  ${({ theme }) => `
    ${theme.breakPoints.largeTablet} {
      position: static;
    }
  `}
`;

export const HeroContentCol = styled.div.attrs(() => ({
  className: 'HeroContentCol',
}))`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  text-align: left;
`;

export const HeroImage = styled.img.attrs(() => ({
  className: 'HeroImage',
}))`
  width: 281px;
  transition: width 0.3s ease-in;
  ${({ theme }) => `
    ${theme.breakPoints.largePhone} {
      width: 200px;
    }

    ${theme.breakPoints.xLargePhone} {
      width: 240px;
    }

    ${theme.breakPoints.tablet} {
      width: 260px;
    }

    ${theme.breakPoints.largeTablet} {
      width: 260px;
    }

    ${theme.breakPoints.desktop} {
      width: 360px;
    }

    ${theme.breakPoints.largeDesktop} {
      width: 360px;
    }
  `}
`;

export const HeroTitle = styled.p`
  color: #333333;
  font-size: 28px;
  line-height: 32px;
  margin: 0;

  ${({ theme }) => `
  ${theme.breakPoints.largeTablet} {
    font-size: 60px;
    line-height: 78px;
    letter-spacing: -2px;
  }
`}
`;

const colors = {
  pink: '#DA4D7A',
  amber: '#F9BA48',
  orange: '#F99848',
  green: '#44C94E',
};

const getColor = (colorName) => colors[colorName] || '#ddd';

export const Circle = styled.div.attrs(() => ({
  className: 'Circle',
}))`
  transition: all 1s ease-in;
  border-radius: 100%;
  background-color: ${({ color }) => getColor(color)};
  position: ${({ position = 'absolute' }) => position};
  ${({ top }) => top ? `top: ${top}` : ''};
  ${({ bottom }) => bottom ? `bottom: ${bottom}` : ''};
  bottom: -600px;
  ${({ left }) => left ? `left: ${left}` : ''};
  ${({ right }) => right ? `right: ${right}` : ''};
  ${({ size = 'md' }) => size === 'md' ? `
    height: 350px;
    width: 350px;
  ` : ''}

  ${({ size = 'sm' }) => size === 'sm' ? `
    height: 220px;
    width: 220px;
  ` : ''}

  ${({ height, width, theme, bottom }) => `
    ${theme.breakPoints.largeTablet} {
      ${bottom ? `bottom: ${bottom}` : ''};
    }
    ${height ? `height:${height};` : ''}
    ${width ? `width:${width};` : ''}
  `}
`;

export const PinkCircle = styled(Circle).attrs(() => ({
  color: 'pink',
}))`
  ${({ theme }) => `
    top: -110px;
    bottom: -600px;
    right: -80px;
    height: 220px;
    width: 220px;
    z-index: 8;
    ${theme.breakPoints.largeTablet} {
      height: 350px;
      width: 350px;
      right: -165px;
      top: -238px;
    }
    ${theme.breakPoints.desktop} {
      height: 430px;
      width: 430px;
      right: -230px;
      top: -312px;
    }
    ${theme.breakPoints.largeDesktop} {
      height: 630px;
      width: 630px;
      right: -260px;
      top: -360px;
    }
    ${theme.breakPoints.biggerScreens} {
      height: 900px;
      width: 900px;
      right: -270px;
      top: -520px;
    }
  `}
`;

export const HeroImageContainer = styled.div`
  position: relative;
  text-align: center;
  margin: 0 auto;
  width: fit-content;
`;


export const HeroDescription = styled.p`
  font-size: 18px;
  color: #8B8B8B;
  line-height: 24px;
`;

export const VisitKineduButton = styled(Button).attrs(() => ({
  rounded: true,
  flat: true,
}))`
  width: 297px;
  height: 50px;
  background-color: #0086D8;
  color: white;

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    width: 297px;
    height: 50px;
    font-size: 16px;
  }
`}
`

export const VisitKineduContainer = styled.div`
text-align: center;
margin: 0;
margin-bottom: 48px;
${({ theme }) => `
${theme.breakPoints.xLargePhone} {
  text-align: left;
  padding-left: 20px;
}
`}

`;
