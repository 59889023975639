import React from "react"
import { Input } from "../LoginCTAForm/styles"
import { Wrapper, SubmitButton, JoiningForm, FormFieldContainer } from "./styles"

const ForParentsForm = () => {

  const onSubmitHandle = (e) => {
    e.preventDefault();
  };

  return (
    <Wrapper>
      <JoiningForm onSubmit={onSubmitHandle}>
        <FormFieldContainer>
          <label htmlFor={`educators-name-${12}`}> Educators name </label>
          <Input
            type="text"
            id={`input-educators-${12}`}
            placeholder="Educators name"
            required
          />
        </FormFieldContainer>
        <FormFieldContainer>
          <label htmlFor={`input-email-${12}`}> Your email </label>
          <Input
            type="email"
            id={`input-email-${12}`}
            placeholder="Educators email"
            required
          />
        </FormFieldContainer>
        <SubmitButton type="submit" >INVITE</SubmitButton>
      </JoiningForm>
    </Wrapper>
  )
}

export default ForParentsForm
